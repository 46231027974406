import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import NotFound from './pages/NotFound';
import Products from "./pages/Products";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Brands from "./pages/Brands";
import Dealers from "./pages/Dealers";

function App() {
    return (
        <Router>
            <Routes>
                {/* Define your routes here */}
                <Route exact path="/" element={<HomePage />} />
                <Route exact path="/products" element={<Products />} />
                <Route exact path="/products/:item" element={<Products />} />
                <Route exact path="/about" element={<About />} />
                <Route exact path="/contact" element={<Contact />} />
                <Route exact path="/brands" element={<Brands />} />
                <Route exact path="/dealers" element={<Dealers />} />
                {/* Add more routes as needed */}
                <Route path="*" element={<NotFound />} />
            </Routes>
        </Router>
    );
}

export default App;