import React from "react";
import Carousel from 'react-material-ui-carousel';
import { Paper } from "@mui/material";
import SlidesData from "../public/img/homepage/slides.json";
import Box from "@mui/material/Box";

const CAROUSAL_IMAGE_HEIGHT = 800;

function CustomCarousel({ height = CAROUSAL_IMAGE_HEIGHT, slidesData = SlidesData }) {
    return (
        <Box sx={{ position: 'relative', padding: { xs: 0, md: 2 }, paddingLeft: { xs: 0, md: '10%' }, paddingRight: { xs: 0, md: '10%' } }}>
            <Carousel
                autoPlay={true}
                interval={3000}
                timeout={1000}
                indicatorIconButtonProps={{
                    style: {
                        padding: '5px',
                        color: 'transparent',
                        border: '2px solid silver',
                        borderRadius: '50%',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                        margin: '0 5px',
                    },
                }}
                activeIndicatorIconButtonProps={{
                    style: {
                        backgroundColor: 'silver',
                        border: '2px solid silver',
                        borderRadius: '50%',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.4)',
                    },
                }}
                indicatorContainerProps={{
                    style: {
                        position: 'absolute',
                        top: `${height - 100}px`, // Adjust as needed
                        left: '50%',
                        transform: 'translateX(-50%)',
                        textAlign: 'center',
                        zIndex: 1, // Ensure it is above the image
                        display: { xs: 'none', md: 'flex' }
                    },
                }}
            >
                {
                    slidesData.slides.map((item, i) =>
                        <Paper key={i}>
                            <Box
                                component="img"
                                sx={{
                                    height: `${height}px`,
                                    width: '100%',
                                    flexGrow: 1,
                                    display: { xs: 'none', md: 'flex' }
                                }}
                                alt={item.alt}
                                src={item.src}
                            />
                            <Box
                                component="img"
                                sx={{
                                    height: `300px`,
                                    width: '100%',
                                    flexGrow: 1,
                                    display: { xs: 'flex', md: 'none' }
                                }}
                                alt={item.alt}
                                src={item.src}
                            />
                        </Paper>
                    )
                }
            </Carousel>
        </Box>
    );
}

export default CustomCarousel;