import React from 'react';
import { Container, Typography, Box, Grid, Paper } from '@mui/material';
import './About.css';
import ResponsiveAppBar from "../Components/ResponsiveAppBar";
import Footer from "../Components/Footer";

const About = () => {
    return (
        <>
            <ResponsiveAppBar />
            <Container maxWidth="lg" className="container">
                <Typography variant="h3" component="h1" gutterBottom className="header" sx={{fontFamily: 'Sofadi One'}}>
                    About Us
                </Typography>
                <Typography variant="body1" paragraph className="section" sx={{fontFamily: 'Sofadi One'}}>
                    Welcome to our company! We are dedicated to providing the best service possible.
                </Typography>
                <Grid container spacing={4} className="section">
                    <Grid item xs={12} md={6}>
                        <Paper elevation={3} className="paper">
                            <Typography variant="h5" component="h2" sx={{fontFamily: 'Sofadi One'}} gutterBottom>
                                Our Mission
                            </Typography>
                            <Typography variant="body1" sx={{fontFamily: 'Sofadi One'}}>
                                Our mission is to deliver high-quality products that bring value to our customers.
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper elevation={3} className="paper">
                            <Typography variant="h5" component="h2"  gutterBottom sx={{fontFamily: 'Sofadi One'}}>
                                Our Vision
                            </Typography>
                            <Typography variant="body1" sx={{fontFamily: 'Sofadi One'}}>
                                We envision a world where our products make a significant impact on people's lives.
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
                <Box sx={{ marginTop: 4, width: '100%' }}>
                    <Paper elevation={3} className="paper">
                        <Typography variant="h4" component="h2" gutterBottom className="header" sx={{fontFamily: 'Sofadi One'}}>
                            About the Company
                        </Typography>
                        <Typography variant="body1" sx={{textAlign: 'left',fontFamily: 'Sofadi One'}}>
                            Welcome to ROYALISTIC, a leading provider of high-quality door fitting hardware solutions based in India. With over couple of years of experience in the industry, we specialise in crafting durable and aesthetically appealing hardware products designed to meet the needs of residential, commercial, and industrial projects.
                        </Typography>
                        <Typography variant="body1" sx={{textAlign: 'left',fontFamily: 'Sofadi One'}}>
                            At ROYALISTIC, our mission is to offer premium door fitting hardware that combines functionality, durability, and style. We believe that door hardware is more than just a functional accessory — it is a critical design element that can elevate the look and feel of any space. Our commitment to quality ensures that every product we offer is crafted with precision and engineered to last.
                        </Typography>
                        <Typography variant="body1" sx={{textAlign: 'left',fontFamily: 'Sofadi One'}}>
                            We offer an extensive range of door hardware solutions, including:
                            <ul>
                                <li>Door Handles: From modern to traditional designs, our door handles are crafted to suit a variety of styles and finishes.</li>
                                <li>Locks & Latches: Ensure security and ease of use with our wide selection of high-quality locks and latches.</li>
                                <li>Hinges: Precision-engineered hinges for smooth, reliable door operation.</li>
                                <li>Sliding Door Systems: Innovative sliding door solutions for residential and commercial spaces.</li>
                                <li>Other Accessories: A variety of accessories including door stoppers, door closers, and more.</li>
                            </ul>
                        </Typography>
                        <Typography variant="body1" sx={{textAlign: 'left',fontFamily: 'Sofadi One'}}>
                            Why Choose Us?
                            <ul>
                                <li>Superior Quality: We use only the highest-quality materials, ensuring that our products are both durable and stylish.</li>
                                <li>Innovative Designs: Our product designs range from classic to contemporary, allowing you to find the perfect hardware to complement any door.</li>
                                <li>Expert Craftsmanship: Each piece is meticulously crafted to meet the highest standards of quality and precision.</li>
                                <li>Competitive Pricing: We offer premium products at competitive prices, ensuring excellent value for our customers.</li>
                                <li>Customer Satisfaction: Our dedicated customer support team is always ready to assist with product selection, customisation, and after-sales service.</li>
                            </ul>
                        </Typography>
                    </Paper>
                </Box>
            </Container>
            <Footer />
        </>
    );
};

export default About;