import React, { useState, useEffect } from 'react';
import { Grid, Card, CardMedia, CardContent, Typography, Box } from '@mui/material';
import { animated } from '@react-spring/web';

import './ProductGrid.css';


const ProductGrid = ({ item_name = "default" }) => {
    const [hovered, setHovered] = useState(null);
    const [productsData, setProductsData] = useState({ products: [] });

    useEffect(() => {
        fetch(`/img/products/${item_name}/products.json`)
            .then(response => response.json())
            .then(data => setProductsData(data))
            .catch(error => console.error('Error loading products:', error));
    }, [item_name]);

    const handleMouseEnter = (index) => {
        setHovered(index);
    };

    const handleMouseLeave = () => {
        setHovered(null);
    };

    return (
        <Box sx={{ flexGrow: 1, padding: 2 }}>
            <Box className="heading-box">
                <Typography variant="h4" component="div" className="heading-text" sx={{fontFamily: 'Sofadi One'}}>
                    {productsData.heading}
                </Typography>
            </Box>
            <Grid container spacing={2}>
                {productsData.products.map((product, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                        <animated.div
                            onMouseEnter={() => handleMouseEnter(index)}
                            onMouseLeave={handleMouseLeave}
                            className="animated-card"
                        >
                            <a href={product.link} style={{ textDecoration: 'none' }}>
                                <Card>
                                    <CardMedia
                                        component="img"
                                        height="400"
                                        image={product.image}
                                        alt={product.name}
                                    />
                                    <CardContent>
                                        <Typography variant="h6" component="div" className="product-name" sx={{fontFamily: 'Sofadi One'}}>
                                            {product.name}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </a>
                        </animated.div>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default ProductGrid;