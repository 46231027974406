import React from 'react';
import { Container, Typography, Grid, Paper } from '@mui/material';
import ResponsiveAppBar from '../Components/ResponsiveAppBar';
import Footer from '../Components/Footer';
import './Dealers.css'; // Create and style this CSS file as needed

const dealers = [
    {
        name: 'Dealer 1',
        location: 'Location 1',
        phone: '+91-1234567890',
        email: 'dealer1@example.com'
    },
    {
        name: 'Dealer 2',
        location: 'Location 2',
        phone: '+91-0987654321',
        email: 'dealer2@example.com'
    },
    // Add more dealers as needed
];

const Dealers = () => {
    return (
        <>
            <ResponsiveAppBar />
            <Container maxWidth="lg" className="container">
                <Typography variant="h3" component="h1" gutterBottom className="header">
                    Our Dealers
                </Typography>
                <Grid container spacing={4} className="section">
                    {dealers.map((dealer, index) => (
                        <Grid item xs={12} md={4} key={index}>
                            <Paper elevation={3} className="paper">
                                <Typography variant="h6" component="h3" gutterBottom align="center" sx={{ fontFamily: 'Sofadi One' }}>
                                    {dealer.name}
                                </Typography>
                                <Typography variant="body1" align="center" sx={{ fontFamily: 'Sofadi One' }}>
                                    {dealer.location}
                                </Typography>
                                <Typography variant="body1" align="center" sx={{ fontFamily: 'Sofadi One' }}>
                                    {dealer.phone}
                                </Typography>
                                <Typography variant="body1" align="center" sx={{ fontFamily: 'Sofadi One' }}>
                                    {dealer.email}
                                </Typography>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Container>
            <Footer />
        </>
    );
};

export default Dealers;