import React from "react";
import { Container, Typography, Grid, Paper, Box } from '@mui/material';
import ResponsiveAppBar from "../Components/ResponsiveAppBar";
import CustomCarousel from "../Components/CustomCarousel";
import Footer from "../Components/Footer";
import './About.css'; // Ensure the CSS file is imported

function HomePage() {
    return (
        <>
            <ResponsiveAppBar />
            <CustomCarousel />
            <Container sx={{width: '82%'}} maxWidth="xl" className="container">
                <Box sx={{ marginTop: 4, width: '100%' }}>
                    <Paper elevation={3} className="paper">
                        <Typography variant="h4" component="h2" gutterBottom className="header"sx={{fontFamily: 'Sofadi One'}}>
                            Company Profile
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Typography variant="body1" align="center">
                                    ‎
                                </Typography>
                                <Typography variant="body1" sx={{ textAlign: 'left',fontFamily: 'Sofadi One' }}>
                                    As a proudly Indian company, we are committed to supporting the Make in India initiative by manufacturing our products locally. We have produced door hardware that meets international standards. In India, we not only ensure the highest quality control but also contribute to the growth of the Indian economy.
                                </Typography>
                                <Typography variant="body1" align="center">
                                    ‎
                                </Typography>
                                <Typography variant="body1" sx={{ textAlign: 'left',fontFamily: 'Sofadi One' }}>
                                    We are conscious of our environmental impact and strive to minimise it through responsible sourcing of materials and eco-friendly manufacturing processes. Our goal is to offer products that not only perform well but also contribute to a sustainable future.
                                </Typography>
                                <Typography variant="body1" align="center">
                                    ‎
                                </Typography>
                                <Typography variant="body1" sx={{ textAlign: 'left',fontFamily: 'Sofadi One' }}>
                                    With a strong presence in India, we will have built a reputation for providing reliable hardware solutions to homeowners, architects, interior designers, and builders. We will also cater to international clients, delivering world-class door hardware to markets in other countries or regions.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <img src="/logo.ico" alt="Company Profile" style={{ width: '60%' }} />
                            </Grid>
                        </Grid>
                    </Paper>
                </Box>
            </Container>
            <Footer />
        </>
    );
}

export default HomePage;