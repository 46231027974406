import React from 'react';
import { Container, Typography, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ResponsiveAppBar from '../Components/ResponsiveAppBar';
import Footer from "../Components/Footer";

function NotFound() {
    const navigate = useNavigate();

    const handleGoHome = () => {
        navigate('/');
    };

    return (
        <>
            <ResponsiveAppBar />
            <Container component="main" maxWidth="md" sx={{ textAlign: 'center', mt: 8 }}>
                <Typography variant="h1" component="h1" gutterBottom>
                    404
                </Typography>
                <Typography variant="h5" component="h2" gutterBottom>
                    Page Not Found
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Sorry, the page you are looking for does not exist.
                </Typography>
                <Box mt={4}>
                    <Button variant="contained" color="primary" onClick={handleGoHome}>
                        Go to Home
                    </Button>
                </Box>
            </Container>
            <Footer />
        </>
    );
}

export default NotFound;