import React from 'react';
import { Container, Typography, Box, Grid, Paper } from '@mui/material';
import './About.css';
import ResponsiveAppBar from "../Components/ResponsiveAppBar";
import Footer from "../Components/Footer";

const Brands = () => {
    return (
        <>
            <ResponsiveAppBar />
            <Container maxWidth="lg" className="container">
                <Typography variant="h3" component="h1" gutterBottom className="header" sx={{fontFamily: 'Sofadi One'}}>
                    Other Brands Owned by Our Company
                </Typography>
                <Box sx={{ marginTop: 4, width: '100%' }}>
                    <Paper elevation={3} className="paper">
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <img src="/img/about/mgm.jpg" alt="Brand 1" style={{ width: '100%' }} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <img src="/img/about/hammer.jpg" alt="Brand 2" style={{ width: '100%' }} />
                            </Grid>
                        </Grid>
                    </Paper>
                </Box>
            </Container>
            <Footer />
        </>
    );
};

export default Brands;