import React from "react";
import ResponsiveAppBar from "../Components/ResponsiveAppBar";
import ProductGrid from "../Components/ProductGrid";
import { useParams } from "react-router-dom";
import { useSpring, animated } from '@react-spring/web';
import Footer from "../Components/Footer";

function Products() {

    const { item } = useParams();

    const fadeIn = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 },
        config: { duration: 1000 },
    });

    return (
        <>
            <ResponsiveAppBar />
            <animated.div style={fadeIn}>
                <ProductGrid item_name={item} />
            </animated.div>
            <Footer />
        </>
    );
}

export default Products;