import React, { useRef } from 'react';
import { Container, Typography, Box, Grid, Paper, TextField, Button } from '@mui/material';
import './Contact.css';
import ResponsiveAppBar from "../Components/ResponsiveAppBar";
import Footer from "../Components/Footer";

const Contact = () => {
    const form = useRef();

    return (
        <>
            <ResponsiveAppBar />
            <Container maxWidth="lg" className="container">
                <Typography variant="h3" component="h1" gutterBottom className="header">
                    Contact Us
                </Typography>
                <Grid container spacing={4} className="section equal-height">
                    <Grid item xs={12} md={6}>
                        <Paper elevation={3} className="paper">
                            <Typography variant="h5" component="h2" gutterBottom sx={{fontFamily: 'Sofadi One'}}>
                                Become A Dealer 👨🏻
                            </Typography>
                            <form ref={form} noValidate autoComplete="off">
                                <TextField
                                    label="Name"
                                    name="user_name"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    sx={{fontFamily: 'Sofadi One'}}
                                    required
                                />
                                <TextField
                                    label="Email"
                                    name="user_email"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    sx={{fontFamily: 'Sofadi One'}}
                                    required
                                />
                                <TextField
                                    label="Message"
                                    name="message"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    multiline
                                    rows={4}
                                    sx={{fontFamily: 'Sofadi One'}}
                                    required
                                />
                                <Button variant="contained" color="primary" type="submit" sx={{fontFamily: 'Sofadi One'}}>
                                    Submit
                                </Button>
                            </form>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper elevation={3} className="paper">
                            <Typography variant="h5" component="h2" gutterBottom sx={{fontFamily: 'Sofadi One'}}>
                                Our Location
                            </Typography>
                            <Typography variant="h6" component="h3" gutterBottom align="center">
                                📍
                            </Typography>
                            <Box className="map-container">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14014.123456789!2d77.089639!3d28.423444!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1d1d1d1d1d1d%3A0x1d1d1d1d1d1d1d1d!2sYour%20Location!5e0!3m2!1sen!2sin!4v1633072871234!5m2!1sen!2sin"
                                    width="100%"
                                    height="400"
                                    style={{ border: 0 }}
                                    allowFullScreen=""
                                    loading="lazy"
                                    title="Google Maps"
                                ></iframe>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
                <Grid container spacing={4} className="section">
                    <Grid item xs={12} md={4}>
                        <Paper elevation={3} className="paper">
                            <Typography variant="h6" component="h3" gutterBottom align="center">
                                📞
                            </Typography>
                            <Typography variant="body1" align="center" sx={{fontFamily: 'Sofadi One'}}>
                                <a href="tel:+919889166661" style={{ textDecoration: 'none', color: 'inherit' }}>
                                    +91-9889166661
                                </a>
                            </Typography>
                            <Typography variant="body1" align="center" sx={{fontFamily: 'Sofadi One'}}>
                                <a href="tel:+919889698000" style={{ textDecoration: 'none', color: 'inherit' }}>
                                    +91-9889698000
                                </a>
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Paper elevation={3} className="paper">
                            <Typography variant="h6" component="h3" gutterBottom align="center">
                                🏢
                            </Typography>
                            <Typography variant="body1" align="center" sx={{fontFamily: 'Sofadi One'}}>
                                G-1232, G-Block, Sushant Lok - 2, Sector 57, Gurugram, Haryana - 122011
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Paper elevation={3} className="paper">
                            <Typography variant="h6" component="h3" gutterBottom align="center">
                                ✉️
                            </Typography>
                            <Typography variant="body1" align="center" sx={{fontFamily: 'Sofadi One'}}>
                                royalistichardware@gmail.com
                            </Typography>
                            <Typography variant="body1" align="center">
                                ‎
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
            <Footer />
        </>
    );
};

export default Contact;