import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import './Footer.css';

const Footer = () => {
    return (
        <Box className="footer">
            <Container maxWidth="lg">
                <Typography variant="body1" align="center" sx={{fontFamily: 'Sofadi One'}}>
                    Corporate Office: G-1232, Sushant Lok - 2, sector 57, Gurugram, HR
                </Typography>
                <Typography variant="body1" align="center" sx={{fontFamily: 'Sofadi One'}}>
                    Marketed by: Raj Lata Metal Works, Kanpur, UP
                </Typography>
                <Typography variant="body1" align="center" sx={{fontFamily: 'Sofadi One'}}>
                    Phone: +91-9889166661 | Email: royalistichardware@gmail.com
                </Typography>
            </Container>
        </Box>
    );
};

export default Footer;